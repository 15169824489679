import { TakeActionDropdownData } from 'components/TakeActionDropdown/types/TakeActionDropdownData';
import { searchGetEventsRoutePath } from 'modules/search/routing/helpers/searchGetEventsRoutePath';
import { searchGetVolopsRoutePath } from 'modules/search/routing/helpers/searchGetVolopsRoutePath';

export const takeActionDropdownDataEn: TakeActionDropdownData = {
  search: {
    title: 'Search',
    items: [
      {
        type: 'volop',
        title: 'Volunteer',
        href: searchGetVolopsRoutePath({ lang: 'en' }),
      },
      {
        type: 'event',
        title: 'Attend an Event',
        href: searchGetEventsRoutePath({ lang: 'en' }),
      },
      {
        type: 'donations',
        title: 'Give Donations',
        href: searchGetVolopsRoutePath({
          lang: 'en',
          filtersByName: { actionType: ['DONATION'] },
        }),
      },
      {
        type: 'petitions',
        title: 'Sign Petitions',
        href: searchGetVolopsRoutePath({
          lang: 'en',
          filtersByName: { actionType: ['PETITION'] },
        }),
      },
    ],
  },
  resources: {
    title: 'Resources',
    items: [
      {
        type: 'idealist-movement',
        title: 'Join the Idealist Action Incubator',
        description:
          'Connect with a member of our team for one-on-one sessions where you’ll collaboratively develop your action plan.',
        href: '/en/action-incubator',
      },
      {
        type: 'recipes-for-action',
        title: 'Try a Recipe for Action',
        description:
          'A curated collection of actionable ideas for creating change.',
        href: '/en/recipes-for-action',
      },
    ],
  },
};
