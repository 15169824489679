import { TakeActionDropdownData } from 'components/TakeActionDropdown/types/TakeActionDropdownData';
import { searchGetEventsRoutePath } from 'modules/search/routing/helpers/searchGetEventsRoutePath';
import { searchGetVolopsRoutePath } from 'modules/search/routing/helpers/searchGetVolopsRoutePath';

export const takeActionDropdownDataPt: TakeActionDropdownData = {
  search: {
    title: 'Busca',
    items: [
      {
        type: 'volop',
        title: 'Voluntariado',
        href: searchGetVolopsRoutePath({ lang: 'pt' }),
      },
      {
        type: 'event',
        title: 'Eventos',
        href: searchGetEventsRoutePath({ lang: 'pt' }),
      },
      {
        type: 'donations',
        title: 'Doações',
        href: searchGetVolopsRoutePath({
          lang: 'pt',
          filtersByName: { actionType: ['DONATION'] },
        }),
      },
    ],
  },
  resources: {
    title: 'Recursos',
    items: [
      {
        type: 'idealist-movement',
        title: 'Movimento Idealista',
        description:
          'Bem Vindo ao Movimento Idealista. Aqui você encontra formas de se conectar e pode conhecer o ecossistema idealista para transformação social.',
        href: '/pt/movimento',
      },
      {
        type: 'knowledge-base',
        title: 'Blog Idealista',
        description:
          'Vamos transformar nossas comunidades? Quer trabalhar no terceiro setor? Recursos e Dicas para o Transformador Social.',
        href: '/pt/blog',
      },
    ],
  },
};
